import * as React from "react";
import { fetchUtils, Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';

import authProvider from './providers/auth/authProvider';
import simpleRestProvider from './providers/data/dataProvider';
import { Login } from './components/Login';
import { ForgotPassword } from './components/ForgotPassword';
import { ChangePassword } from './components/ChangePassword';
import orders from "./resources/orders/";
import events from "./resources/events/";
import {myTheme} from "./theme";
/* import { InstallApp } from "./components/InstallApp"; */

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  let token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  options.headers.set('Access-Control-Allow-Origin', 'http://localhost:3001, http://localhost:3000,');

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

const App = () => (
  <BrowserRouter>

    <Admin
      theme={myTheme}
      loginPage={Login}
      //dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="Example Admin">

      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </CustomRoutes>
      <Resource name="orders" {...orders} />
      <Resource name="events" {...events} />
    </Admin>
{/*     <InstallApp/> */}
  </BrowserRouter>
);

export default App;
