import * as React from "react";
import { Show, useShowController, SimpleShowLayout, TextField, DateField, ShowButton } from 'react-admin';
import Button from '@mui/material/Button';
import { getFile } from "../../utils";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default () => {

    const {
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
    } = useShowController();

    let files = record?.files?.map(file => 
        <div>
            <Button variant="contained" onClick={() => {
                getFile({
                    endpoint: `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/files/${file?.attach_id}`,
                    filename:`${resource}_${record?.id}_${file?.attach_name}`
                })
            }} >{file?.attach_name}</Button>
            {file.issued_at && 
                <Typography variant="caption">{new Date(file.issued_at).toLocaleString()}</Typography>
            }
        </div>
    )
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="name" />
                <DateField source="start_date" locales="en-GB" />
                <DateField source="end_date" locales="en-GB" />
                <>
                {record?.info_sheet &&
                    <Button variant="contained" onClick={() => {
                        getFile({
                            endpoint: `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/info-sheet/pdf`,
                            filename:`${resource}_${record?.id}_info-sheet.pdf`
                        })
                    }} >Info sheet PDF</Button>
                }
                {record?.info_sheet &&
                    <ShowButton onClick={() => {
                        // setOpen(false);
                        let url = `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/info-sheet`;
                        window.open(url, '_blank').focus();
                    }} >View Info sheet</ShowButton>
                }
                </>
                {record?.info_sheet?.issued_at && <DateField showTime label="Info sheet issued at" source="info_sheet.issued_at" locales="en-GB" />}
                <Divider/>
                <Typography variant="body2">Order files</Typography>
                {files}
            </SimpleShowLayout>
        </Show>
    )
};