// src/form-component/FormInputText.tsx
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
export const VoucherEmailInput = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        pattern:
        {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
        },
        required: "Enter email", 
        
      }}
      render={({
        field: { onChange, value, },
        fieldState: { error },
        formState,
      }) => 
        (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          //fullWidth
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};