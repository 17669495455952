import * as React from "react";
import { useListContext, Datagrid, DatagridBody, DatagridHeader, useListSortContext, List, TextField, RecordContextProvider, DateField, ShowButton, TopToolbar } from 'react-admin';
import Button from '@mui/material/Button';
import { getFile } from "../../utils";
import { TableCell, TableRow, TableHead, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ListActionButtons from "../../components/ListActionButtons";
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const inverseOrder = sort => (sort === 'ASC' ? 'DESC' : 'ASC');

const MyDatagridRow = ({ record, id, onToggleItem, children, selected, selectable }) => {
    
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    let [orderName, ...otherFields] = React.Children.toArray(children);
    return (
    <RecordContextProvider value={record}>
        <TableRow>
            {/* data columns based on children */}

                <TableCell key={`${id}-${orderName.props.source}`}>
                    <Link to={`/events/${record?.id}/show`}>
                        {orderName}
                    </Link>
                </TableCell>

            {otherFields.map(field => (
                <TableCell key={`${id}-${field.props.source}`}>
                    {field}
                </TableCell>
            ))}

            <TableCell>
                <ListActionButtons>
                    <Button startIcon={<RemoveRedEyeIcon />} >
                        <Link to={`/events/${record?.id}/show`}>
                            Show
                        </Link>
                    </Button>
                {record?.voucher &&
                    <Button
                        startIcon={<DownloadIcon />}
                        variant="text" onClick={() => {
                            getFile({
                                endpoint: `${process.env.REACT_APP_API_URL}/events/${record?.id}/voucher`,
                                filename: `events_${record?.id}_voucher.pdf`
                            })
                        }} > Voucher </Button>
                }
                {record?.voucher &&
                    <Button
                        startIcon={<RemoveRedEyeIcon />}
                        onClick={() => {
                            let url = `${process.env.REACT_APP_API_URL}/events/${record?.id}/voucher/view`;
                            if(isSmall){
                                window.open(url,'_self').focus();
                            }else {
                                window.open(url, '_blank').focus();
                            }
                        }} >View voucher</Button>
                }
                {record?.rooming_list && 
                    <Button 
                        startIcon={<DownloadIcon />}
                        variant="text" onClick={() => {

                        getFile({
                            endpoint: `${process.env.REACT_APP_API_URL}/events/${record?.id}/rooming-list`,
                            filename:`events_${record?.id}_rooming_list.xls`
                        })
                    }} > Rooming List </Button>
                }
                </ListActionButtons>
            </TableCell>

            <TableCell>
                <DateField source="updated_at" label="Saved" showTime locales="en-GB" options={{ hour12: false }} />
            </TableCell>
        </TableRow>
    </RecordContextProvider>
)};


const MyDatagridHeader = ({ children}) => {
    return (

        <DatagridHeader >
                {children}
                <TableCell label="Actions"></TableCell>
                <TableCell source="updated_at"></TableCell>
        </DatagridHeader>
    )
};

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => {
    return (<Datagrid {...props} 
    body={<MyDatagridBody/>} 
    header={<MyDatagridHeader {...props} />} 
/>)
};

export default () => {

    return (
        <List
        actions={<TopToolbar />} 
        sx={{
            "& .MuiPaper-root": {
                overflow: { xs: 'scroll', md: 'auto' },
                width: { xs: '100vw', md: '100%' }
            },
        }}
        >
            <MyDatagrid  bulkActionButtons={false} >
                <TextField source="order.name" />
                <TextField source="name" />
                <TextField source="type" />
                <DateField source="start_time"  locales="en-GB" />
            </MyDatagrid>
        </List>
    )
};