import { defaultTheme } from 'react-admin';

export const myTheme = {
    ...defaultTheme,
    palette: {
        main: {main:'#6fcf5f'},
        primary: {main:'#6fcf5f'},
        secondary: {main:'#6fcf5f'},
    },
};
