import * as React from "react";
import { List, Datagrid, TextField, DateField, ShowButton, TopToolbar } from 'react-admin';

export default () => (
    <List
        actions={<TopToolbar />}
        sx={{
            "& .MuiPaper-root": {
                overflow: { xs: 'scroll', md: 'auto' },
                width: { xs: '100vw', md: '100%' }
            },
        }}
    >
        <Datagrid  bulkActionButtons={false}>
            <TextField source="name" />
            <DateField source="start_date" locales="en-GB" />
            <DateField source="end_date" locales="en-GB" />
            <ShowButton />
            <DateField showTime options={{ hour12: false }} source="updated_at" locales="en-GB" />
        </Datagrid>
    </List>
);