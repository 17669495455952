import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, useShowController, ShowButton } from 'react-admin';
import Button from '@mui/material/Button';
import { getFile } from "../../utils";
import DownloadIcon from '@mui/icons-material/Download';
import { useMediaQuery } from '@mui/material';
import { VoucherForm } from "../../components/VoucherForm";



export default () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const {
        record, // record fetched via dataProvider.getOne() based on the id from the location
        resource, // the resource name, deduced from the location. e.g. 'posts'
    } = useShowController();


    return (

        <Show>
            <SimpleShowLayout>
                <TextField source="order.name" />
                <TextField source="name" />
                <TextField source="type" />
                <DateField source="start_time" locales="en-GB" />
                <DateField source="end_time" locales="en-GB" />
                <DateField showTime locales="en-GB" options={{ hour12: false }} source="updated_at" />
                {record?.voucher && 
                    <>
                    <div>
                        <Button 
                        startIcon={<DownloadIcon />}
                        variant="contained" onClick={() => {
                            getFile({
                                endpoint: `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/voucher`,
                                filename:`${resource}_${record?.id}_voucher.pdf`
                            })
                        }} > Voucher </Button>
                        <ShowButton
                        label="View voucher"
                        onClick={() => {
                            let url = `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/voucher/view`;
                            if(isSmall){
                                window.open(url,'_self').focus();
                            }else {
                                window.open(url, '_blank').focus();
                            }
                        }} ></ShowButton>
                    </div>
                        <VoucherForm endpoint={`${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/send-voucher`} />
                    </>
                }
                <>
                {record?.rooming_list && 
                    <Button 
                        startIcon={<DownloadIcon />}
                        variant="contained" onClick={() => {

                        getFile({
                            endpoint: `${process.env.REACT_APP_API_URL}/${resource}/${record?.id}/rooming-list`,
                            filename:`${resource}_${record?.id}_rooming_list.xls`
                        })
                    }} > Rooming List </Button>
                }
                </>
                {record?.voucher?.issued_at && <DateField showTime locales="en-GB" options={{ hour12: false }} source="voucher.issued_at" /> }
                {record?.rooming_list?.issued_at && <DateField showTime locales="en-GB" options={{ hour12: false }} source="rooming_list.issued_at" /> }
            </SimpleShowLayout>
        </Show>
    )
};


