import { isJwtExpired } from "../../utils";

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(` ${process.env.REACT_APP_API_URL}/auth/login `, {
            method: 'POST',
            body: JSON.stringify({ email:username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('token', auth.token);
            })
            .catch(() => {
                throw new Error('Invalid credentials');
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403 ) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        // Required for the authentication to work
        return localStorage.getItem('token') && !isJwtExpired(localStorage.getItem('token')) ?  Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    // ...
};

export default authProvider;