import React from "react";
import { Button, Snackbar, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import { VoucherEmailInput } from "./VoucherEmailInput";

const defaultValues = {
    email: "",
};


export const VoucherForm = ({ endpoint }) => {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("success");

    const { handleSubmit, reset, control, formState:{isSubmitting} } = useForm({
        defaultValues: defaultValues,
    });


    const onSubmit = async (data) => {

        let token = localStorage.getItem('token');
        let postResult = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        if(postResult.status ===  201){
            setMessage('Sent')
            setSeverity("success")
            reset();
        }else {
            setMessage('Error')
            setSeverity("error")
        }
        setOpen(true);
    };

    return (
        <div style={{
            marginTop: "10px",
            display: "grid",
            gridTemplateColumns: "fit-content(200px) fit-content(200px)",
            alignItems: "start"

        }}>
            <VoucherEmailInput name="email" control={control} label="Send voucher to email" />
            <Button style={{
                marginLeft: "8px",
                marginTop: "8px",
                marginBottom: "4px"
            }}disabled={isSubmitting} onClick={handleSubmit(onSubmit)} variant={"contained"}>
                Send voucher
            </Button>
            <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={6000}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};