import jwt_decode from 'jwt-decode';



export const isJwtExpired = (token) => {
  if (typeof (token) !== 'string' || !token) throw new Error('Invalid token provided');

  let isJwtExpired = false;
  const { exp } = jwt_decode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
}

export const getFile = (data) => {
  const {endpoint, filename} = data;

  fetch(
    endpoint, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  })
    .then((res) => {

      if (res.ok) {
        return res.blob();
      }
      return Promise.reject(res);

    })
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = filename;
      a.click();
    }).catch(error => {
      throw new Error(error.statusText);
    });
}